<template>
    <b-container fluid>
        <div class="card card-primary card-outline">
            <div class="card-header">
                <div class="card-title">
                    Manss Blacklist
                </div>
                <div class="card-tools" v-if="$auth.check('settings.manss_blacklist.create')">
                    <button class="btn btn-primary btn-sm" @click="saveBlacklist">Speichern</button>
                </div>
            </div>
            <div class="card-body">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th style="width: 80px;">Nr.</th>
                            <th>HAN</th>
                            <th style="width: 40px">Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(blacklistItem, index) in blacklist" :key="index">
                            <td>{{ index+1 }}</td>
                            <td>
                                <input class="form-control form-control-sm" v-model="blacklist[index].han" />
                            </td>
                            <td>
                                <button class="btn btn-sm btn-danger" :disabled="blacklist.length <= 1" @click="blacklist.splice(index, 1)"><i class="fas fa-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer text-right">
                <div class="card-tools">
                    <button class="btn btn-primary btn-sm" @click="saveBlacklist" v-if="$auth.check('settings.manss_blacklist.create')">Speichern</button>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: 'ManssBlacklistIndex',
    title: 'Manss Blacklist',
    data() {
        return {
            blacklist: [],
            form: new window.Form({
                items: []
            })
        }
    },
    watch: {
        'blacklist': {
            handler() {
                if(this.blacklist.length > 0)
                {
                    if(this.blacklist[this.blacklist.length - 1].han != null)
                    {
                        this.blacklist.push({id: null, han: null});
                    }
                } else
                {
                    this.blacklist.push({id: null, han: null});
                }
            },
            deep: true,
        }
    },
    methods: {
        saveBlacklist() {
            var filtered = this.blacklist.filter(function (el) {
                return el.han != null;
            });

            this.axios
                .post("/settings/manss-blacklist", {
                    items: filtered
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Blacklist erfolgreich gespeichert.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.getBlacklist();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Fehler beim speichern'
                    })
                })
            console.log(filtered);
        },
        getBlacklist() {
            this.axios
                .get("/settings/manss-blacklist")
                .then((response) => {
                    this.blacklist = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Blacklist ist etwas schief gelaufen'
                    })
                });
        }
    },
    mounted() {
        this.getBlacklist();
    }
}
</script>